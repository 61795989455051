import axios from 'axios';

export default {
  data() {
    return {
      noteTagMasterList: [],
      noteTagList: [],
      blockPublicUserList: [],
    };
  },
  inject: ['snack'],
  methods: {
    async getNoteTagMasterListByWorldId(worldId) {
      try {
        const response = await axios.get(this.serverPath + `/api/NoteTag/getNoteTagMasterList/${worldId}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching note tag master list:', error);
      }
    },
    async getNoteTagMasterList() {
      try {
        const response = await axios.get(this.serverPath + `/api/NoteTag/admin/getNoteTagMasterList`);
        return response.data;
      } catch (error) {
        console.error('Error fetching note tag master list:', error);
      }
    },
    async addNoteTagMaster(noteTagMaster) {
      try {
        await axios.post(this.serverPath + '/api/NoteTag/admin/addNoteTagMaster', {noteTagMaster});
        // Refresh the list after adding
        await this.getNoteTagMasterList(noteTagMaster.worldId);
      } catch (error) {
        console.error('Error adding note tag master:', error);
      }
    },
    async updateNoteTagMaster(noteTagMaster) {
      try {
        await axios.post(this.serverPath + '/api/NoteTag/admin/updateNoteTagMaster', {noteTagMaster});
        // Refresh the list after updating
        await this.getNoteTagMasterList(noteTagMaster.worldId);
        this.snack("success", "tag情報を更新しました。")
      } catch (error) {
        console.error('Error updating note tag master:', error);
        this.snack("error", "tag情報の更新に失敗しました。")
      }
    },
    async deleteNoteTagMaster(id) {
      try {
        await axios.post(this.serverPath + `/api/NoteTag/admin/deleteNoteTagMaster/${id}`);

        this.snack("success", "tagを削除しました。")
      } catch (error) {
        console.error('Error deleting note tag master:', error);
      }
    },
    async setNoteTag(noteId, noteTagId) {
      try {
        await axios.post(this.serverPath + '/api/NoteTag/setNoteTag', {noteId, noteTagId});
        // Refresh the note tag list after setting
        await this.getNoteTagList(noteId);
      } catch (error) {
        console.error('Error setting note tag:', error);
      }
    },
    async setNoteTagList(noteId, noteTagIdList) {
      try {
        await axios.post(this.serverPath + '/api/NoteTag/setNoteTags', {noteId, noteTagIdList});
        // Refresh the note tag list after setting
        await this.getNoteTagList(noteId);
        this.snack("success", "タグを紐づけしました。")
      } catch (error) {
        console.error('Error setting note tag list:', error);
        this.snack("error", error.response.data.errorMessage)
      }
    },
    async deleteNoteTag(noteId, noteTagId) {
      try {
        await axios.post(this.serverPath + '/api/NoteTag/deleteNoteTag', {noteId, noteTagId});

        //500ms待機
        await new Promise(resolve => setTimeout(resolve, 500));
        // Refresh the note tag list after deleting
        await this.getNoteTagList(noteId);

        this.snack("success", "タグの紐づけを解除しました。")
      } catch (error) {
        console.error('Error deleting note tag:', error);
      }
    },
    async getNoteTagList(noteId) {
      try {
        const response = await axios.get(this.serverPath + `/api/NoteTag/getNoteTagList/${noteId}`);
        this.noteTagList = response.data;
      } catch (error) {
        console.error('Error fetching note tag list:', error);
      }
    },

    async getNoteTagMasterListForSearch(worldId) {
      try {
        const response = await axios.get(this.serverPath + `/api/NoteTag/getNoteTagMasterListForSearch/${worldId}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching note tag master list:', error);
      }
    },
    async blockPublicUser(characterId, publicUserId) {
      try {
        await axios.post(this.serverPath + '/api/NoteTag/blockPublicUser', {characterId, publicUserId});
        // Refresh the block list after blocking
        await this.getBlockPublicUserList(characterId);
      } catch (error) {
        console.error('Error blocking public user:', error);
        this.snack("error", error.response.data.errorMessage)
      }
    },
    async getBlockPublicUserList(characterId) {
      try {
        const response = await axios.get(this.serverPath + `/api/NoteTag/getBlockPublicUserList/${characterId}`);
        return response.data;
      } catch (error) {
        console.error('Error fetching block public user list:', error);
      }
    },
    async unBlockPublicUser(characterId, publicUserId) {
      try {

        await axios.post(this.serverPath + '/api/NoteTag/unBlockPublicUser', {characterId, publicUserId});
        // Refresh the block list after unblocking

        this.snack("success", "ユーザーのブロックを解除しました。")
      } catch (error) {
        console.error('Error unblocking public user:', error);
      }
    },
  },
};
