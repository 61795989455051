//
// 定数
//

// 広場キャラクター用定数
// const _hiroba_koshiki = "1"; //公式キャラクター
// const _hiroba_chara1 = "2"; //広場キャラクター１
// const _hiroba_chara2 = "3"; //広場キャラクター２
// const _hiroba_toroku = "4"; //登録画面キャラクター

// シナリオ検索用定数
const _detail = "Detail"; //シナリオ詳細検索

// 画像のデフォルトファイル名
const _no_image = "noImage";

//キャラクターシート開閉
const _characterSheetId = [];
const _dialogCharacterSheet = false;
const _characterSheetMini = false;

// シナリオ状態
const _shinario_yoyaku = "1";
const _shinario_chusen = "2";
const _shinario_boshu = "3";
const _shinario_sodan = "4";
const _shinario_kaiketsuChu = "5";
const _shinario_kanryo = "6";
const _shinario_yusen = "7";
const _shinario_fuseiritsu = "8";
const _shinario_teishutsu = "9";
const _shinario_kyakka = "10";
const _shinario_kokaiMachi = "11";
const _shinario_chikoku = "12";

// シナリオ種別
const _shinario_short = "1";
const _shinario_event = "2";
const _shinario_battle = "3";
const _shinario_series = "4";

// ショップタイプ
const _shop_type_item = "1";
const _shop_type_skill = "2";

// 申請状態
const _mi_shinsei = "0";
const _shinsei_chu = "1";
const _shinsei_shonin_zumi = "2";
const _shinsei_kyakka = "3";

// 時間
const _timeOmakase_value = "おまかせ";

// クリエイターフラグ
const _creator_illustrator = "1"; //イラストレーター
const _creator_voice = "2"; //ボイスアクター
const _creator_writer = "3"; //ライター

// TODO:【仮対応】ノベル受発注、納品用
const _character_novel = "30"; // キャラクターノベル
const _character_novel_group = "31"; // キャラクターノベル（グループ）
const _world_novel = "32"; // ワールドノベル
const _world_novel_group = "33"; // ワールドノベル（グループ）
const _world_novel_theme = "34"; // ワールドノベル（テーマ）
const _novel_option_moji_up = "30"; // ノベル公式オプション：文字数アップ
const _novel_option_mob_detail = "32"; // ノベル公式オプション：モブ詳細

// ファンレター用フラグ
const _master = "1"; // マスター
const _creator = "2"; // クリエイター

//
// テキスト

const _opening = "オープニング";
const _explanation = "解説";
const _from_master = "マスターより";
const _playing = "プレイング";
const _replay = "リプレイ";
const _joined_characters = "参加者";
const _consultation_board = "相談掲示板";
const _re_load = "リロード";
const _irai_list = "依頼リスト";
const _hokoku_list = "報告書リスト";
const _my_irai_list = "参加中の依頼";
const _my_hokoku_list = "参加した依頼";
const _uwasa_no_bokensha = "うわさの冒険者";
const _master_room = "マスタールーム";
const _yoyaku_end = "予約受付終了";
const _shuppatsu = "出発";
const _coming_soon = "公開予定";
const _release = "公開";
const _nin = "人";
const _vertical_bar = "|";
const _hyphen = "-";
const _slash = "/";
const _more_show = "もっと見る";
const _remark = "発言";
const _comment_delete = "この投稿は削除されました";
const _shinkiToroku = "新規キャラクター作成";
const _misobi = "―未装備―";
const _profile = "プロフィール";
const _gallery = "ギャラリー";
const _message = "メッセージ";

const _obj_free_item_1 = "FreeItem1";
const _obj_free_item_2 = "FreeItem2";
const _obj_free_item_3 = "FreeItem3";
const _obj_free_item_4 = "FreeItem4";

//
// ボタン名称
//
const _btn_close = "とじる";
const _btn_ok = "はい";
const _btn_no = "いいえ";
const _btn_cancel = "キャンセル";
const _btn_delete = "削除";

//
// メッセージ
//
const _msg_loading = "読込中";
const _msg_no_data = "データがありません。";
const _msg_timeout =
  "情報を取得できませんでした。時間をおいてやり直してください。";
const _msg_kakunin = "確認";
const _msg_error = "エラー";
const _msg_input_error = "入力エラー";
const _msg_input_error_detail =
  "入力内容に誤りがあるようです。エラー表示をご確認ください。";
const _msg_save = "設定を保存しました";
const _msg_coinLack = "アルパカコインが不足しています";
const _msg_shinseiKekka_error = "申請結果が未選択です。ご確認ください。";

//
// カラー
//

const _colorPrimary = "#ff5252";
const _colorSecondary = "#66beba";
const _colorAccent = "#efcd5d";
const _colorText = "#3e3532";
const _colorTextSub = "#7f7471";
const _colorError = "#dc5c4f";
const _colorInfo = "#3a8dd1";
const _colorSuccess = "#51a57e";
const _colorWarning = "#ffc107";
const _colorGray2 = "#f2ece6";
const _colorGray1 = "#f6f4f2";

export default {
  data() {
    return {
      Detail: _detail,
      NoImage: _no_image,

      CharacterSheetId: _characterSheetId,
      DialogCharacterSheet: _dialogCharacterSheet,
      CharacterSheetMini: _characterSheetMini,

      ShinarioYoyaku: _shinario_yoyaku,
      ShinarioChusen: _shinario_chusen,
      ShinarioBoshu: _shinario_boshu,
      ShinarioSodan: _shinario_sodan,
      ShinarioKaiketsuChu: _shinario_kaiketsuChu,
      ShinarioKanryo: _shinario_kanryo,
      ShinarioYusen: _shinario_yusen,
      ShinarioFuseiritsu: _shinario_fuseiritsu,
      ShinarioTeishutsu: _shinario_teishutsu,
      ShinarioKyakka: _shinario_kyakka,
      ShinarioKokaiMachi: _shinario_kokaiMachi,
      ShinarioChikoku: _shinario_chikoku,
      ShinarioShort: _shinario_short,
      ShinarioEvent: _shinario_event,
      ShinarioBattle: _shinario_battle,
      ShinarioSeries: _shinario_series,
      ShopTypeItem: _shop_type_item,
      ShopTypeSkill: _shop_type_skill,
      MiShinsei: _mi_shinsei,
      ShinseiChu: _shinsei_chu,
      ShinseiShoninZumi: _shinsei_shonin_zumi,
      ShinseiKyakka: _shinsei_kyakka,
      TimeOmakaseValue: _timeOmakase_value,
      CreatorIllustrator: _creator_illustrator,
      CreatorVoice: _creator_voice,
      CreatorWriter: _creator_writer,
      CharacterNovel: _character_novel,
      CharacterNovelGroup: _character_novel_group,
      WorldNovel: _world_novel,
      WorldNovelGroup: _world_novel_group,
      WorldNovelTheme: _world_novel_theme,
      NovelOptionMojiUp: _novel_option_moji_up,
      NovelOoptionMobDetail: _novel_option_mob_detail,
      Master: _master,
      Creator: _creator,
      Opening: _opening,
      Explanation: _explanation,
      FromMaster: _from_master,
      Playing: _playing,
      Replay: _replay,
      Joined_Characters: _joined_characters,
      Consultation_Board: _consultation_board,
      ReLoad: _re_load,
      IraiList: _irai_list,
      HokokuList: _hokoku_list,
      MyIraiList: _my_irai_list,
      MyHokokuList: _my_hokoku_list,
      UwasaNoBokensha: _uwasa_no_bokensha,
      MasterRoom: _master_room,
      YoyakuEnd: _yoyaku_end,
      Shuppatsu: _shuppatsu,
      ComingSoon: _coming_soon,
      Release: _release,
      Nin: _nin,
      VerticalBar: _vertical_bar,
      Hyphen: _hyphen,
      Slash: _slash,
      MoreShow: _more_show,
      Remark: _remark,
      CommentDelete: _comment_delete,
      ShinkiToroku: _shinkiToroku,
      Misobi: _misobi,
      Profile: _profile,
      Gallery: _gallery,
      Message: _message,
      ObjFreeItem1: _obj_free_item_1,
      ObjFreeItem2: _obj_free_item_2,
      ObjFreeItem3: _obj_free_item_3,
      ObjFreeItem4: _obj_free_item_4,
      Btn_Close: _btn_close,
      Btn_Ok: _btn_ok,
      Btn_No: _btn_no,
      Btn_Cancel: _btn_cancel,
      Btn_Delete: _btn_delete,
      Msg_loading: _msg_loading,
      Msg_no_data: _msg_no_data,
      Msg_timeout: _msg_timeout,
      Msg_kakunin: _msg_kakunin,
      Msg_error: _msg_error,
      Msg_input_error: _msg_input_error,
      Msg_input_error_detail: _msg_input_error_detail,
      Msg_save: _msg_save,
      Msg_coinLack: _msg_coinLack,
      Msg_shinseiKekka_error: _msg_shinseiKekka_error,
      ColorPrimary: _colorPrimary,
      ColorSecondary: _colorSecondary,
      ColorAccent: _colorAccent,
      ColorText: _colorText,
      ColorTextSub: _colorTextSub,
      ColorError: _colorError,
      ColorInfo: _colorInfo,
      ColorSuccess: _colorSuccess,
      ColorWarning: _colorWarning,
      ColorGray2: _colorGray2,
      ColorGray1: _colorGray1,
    };
  },
};
