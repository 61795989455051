/**
 * APIはこんな感じで記述する
 * getScenarioReactForAdmin: async function (worldId, templateId) {
 *       let url =
 *         this.serverPath +
 *         `/api/ScenarioReact/admin/get/${worldId}/${templateId}`
 *       let response = await axios.get(url)
 *       if (response.data) {
 *         return response.data
 *       }
 *     },
 */
import axios from 'axios'

export default {
  methods: {
    //GET /api/WorldEventPage/admin/getBlobThumbList/{worldId}
    // 必要なパラメーター：worldId (Path Parameter)
    getBlobThumbListForAdmin: async function (worldId) {
      let url = this.serverPath + `/api/WorldEventPage/admin/getBlobThumbList/${worldId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    // GET /api/WorldEventPage/admin/getEventPageList/{worldId}
    // 必要なパラメーター：worldId (Path Parameter)
    getEventPageListForAdmin: async function (worldId) {
      let url = this.serverPath + `/api/WorldEventPage/admin/getEventPageList/${worldId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    // POST /api/WorldEventPage/admin/saveEventPage
    // 必要なパラメーター：worldEventPage (RequestBody)
    saveEventPageForAdmin: async function (worldEventPage) {
      let url = this.serverPath + `/api/WorldEventPage/admin/saveEventPage`
      let response = await axios.post(url, worldEventPage)
      if (response.data) {
        return response.data
      }
    },

    // POST /api/WorldEventPage/admin/renameEventPage
    // 必要なパラメーター：worldId, oldEventId, newEventId (RequestBody)
    renameEventPageForAdmin: async function (worldId, oldEventId, newEventId) {
      let url = this.serverPath + `/api/WorldEventPage/admin/renameEventPage`
      let response = await axios.post(url, {worldId: worldId, oldEventId: oldEventId, newEventId: newEventId})
      if (response.data) {
        return response.data
      }
    },

    // POST /api/WorldEventPage/admin/deleteEventPage
    // 必要なパラメーター：worldId, eventId (RequestBody)
    deleteEventPageForAdmin: async function (worldId, eventId) {
      let url = this.serverPath + `/api/WorldEventPage/admin/deleteEventPage`
      let response = await axios.post(url, {worldId: worldId, eventId: eventId})
      if (response.data) {
        return response.data
      }
    },

    // POST /api/WorldEventPage/admin/saveEventPageContent
    // 必要なパラメーター：worldId, eventId, contentList (RequestBody)
    saveEventPageContentForAdmin: async function (worldId, eventId, contentList) {
      let url = this.serverPath + `/api/WorldEventPage/admin/saveEventPageContent`
      let response = await axios.post(url, {worldId: worldId, eventId: eventId, contentList: contentList})
      if (response.data) {
        return response.data
      }
    },
    //  @GetMapping("/getBlob/{worldId}/{blobType}/{blobName}")
    // GET /api/WorldEventPage/getBlob/{worldId}/{blobType}/{blobName}
    // 必要なパラメーター：worldId, blobType, blobName (Path Parameters)
    getBlobWithMain: async function (worldId, blobType, blobName) {
      let url = this.serverPath + `/api/WorldEventPage/getBlob/${worldId}/${blobType}/${blobName}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    // POST /api/WorldEventPage/admin/deleteEventPageContent
    // 必要なパラメーター：worldId, eventId, blobNo (RequestBody)
    deleteEventPageContentForAdmin: async function (worldId, eventId, blobNo) {
      let url = this.serverPath + `/api/WorldEventPage/admin/deleteEventPageContent`
      let response = await axios.post(url, {worldId: worldId, eventId: eventId, blobNo: blobNo})
      if (response.data) {
        return response.data
      }
    },
    ///getEventPageContentList/{worldId}/{eventId}
    // GET /api/WorldEventPage/admin/getEventPageContentList/{worldId}/{eventId}
    // 必要なパラメーター：worldId, eventId (Path Parameters)
    getEventPageContentList: async function (worldId, eventId) {
      let url = this.serverPath + `/api/WorldEventPage/admin/getEventPageContentList/${worldId}/${eventId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    //@PostMapping("/saveTopContents")
    // POST /api/WorldEventPage/admin/saveTopContents
    // 必要なパラメーター：worldId, topContentsList (RequestBody)
    saveTopContents: async function (topContentsId, worldId, eventId, openDateTimeFrom) {

      //openDateTimeFormが"2024-05-24T22:00"この形式の場合、  @JsonFormat(pattern = "yyyy-MM-dd HH:mm:ss")でエラーが発生するため変換する
      //Tをスペースに変換
      openDateTimeFrom = openDateTimeFrom + ":00"
      openDateTimeFrom = openDateTimeFrom.replace("T", " ")

      let url = this.serverPath + `/api/WorldEventPage/admin/saveTopContents`
      let response = await axios.post(url, {
        topContentsId: topContentsId,
        worldId: worldId,
        eventId: eventId,
        openDateTimeFrom: openDateTimeFrom
      })
      if (response.data) {
        return response.data
      }
    },


    // POST /api/WorldEventPage/admin/saveBlob
    // 必要なパラメーター：worldEventBlob (RequestBody)
    saveBlob: async function (worldEventBlob) {
      let url = this.serverPath + `/api/WorldEventPage/admin/saveBlob`
      let response = await axios.post(url, worldEventBlob)
      if (response.data) {
        return response.data
      }
    },

    // POST /api/WorldEventPage/admin/deleteBlob
    // 必要なパラメーター：worldEventBlob (RequestBody)
    deleteBlob: async function (worldId, blobType, blobName) {
      let url = this.serverPath + `/api/WorldEventPage/admin/deleteBlob`
      let response = await axios.post(url, {
        worldId: worldId,
        blobType: blobType,
        blobName: blobName
      })
      if (response.data) {
        return response.data
      }
    },

    // GET /api/WorldEventPage/admin/getBlob/{worldId}/{blobType}/{blobName}
    // 必要なパラメーター：worldId, blobType, blobName (Path Parameters)
    getBlob: async function (worldId, blobType, blobName) {
      let url = this.serverPath + `/api/WorldEventPage/admin/getBlob/${worldId}/${blobType}/${blobName}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },
    // POST /api/WorldEventPage/admin/renameBlob
    // 必要なパラメーター：oldBlobName, newBlobName, worldId, blobType (RequestBody)
    renameBlob: async function (oldBlobName, newBlobName, worldId, blobType) {
      let url = this.serverPath + `/api/WorldEventPage/admin/renameBlob`
      let response = await axios.post(url, {
        oldBlobName: oldBlobName,
        newBlobName: newBlobName,
        worldId: worldId,
        blobType: blobType
      })
      if (response.data) {
        return response.data
      }
    },
    // GET /api/WorldEventPage/admin/getTopContentsList/{worldId}
    // 必要なパラメーター：worldId (Path Parameter)
    getTopContentsList: async function (worldId) {
      let url = this.serverPath + `/api/WorldEventPage/admin/getTopContentsList/${worldId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },

    // GET /api/WorldEventPage/admin/getEventPage/{worldId}/{eventId}
    // 必要なパラメーター：worldId, eventId (Path Parameters)
    getEventPage: async function (worldId, eventId) {
      let url = this.serverPath + `/api/WorldEventPage/getEventPage/${worldId}/${eventId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    },
    // GET /api/WorldEventPage/admin/getTopContents/{worldId}
    // 必要なパラメーター：worldId (Path Parameter)
    getTopContents: async function (worldId) {
      let url = this.serverPath + `/api/WorldEventPage/getTopContents/${worldId}`
      let response = await axios.get(url)
      if (response.data) {
        return response.data
      }
    }
  }
}
