export default {
  data() {
    return {};
  },

  filters: {},

  methods: {
    performAndGetCalcResult(characterInfo,classList,equipItemList,equipSkillList, parameterChangeHistory){
      //store内のparameterNameListを取得し、パラメーター計算用のリストを作成
      let parameterNameList = this.$store.state.parameterInfo[this.$store.state.worldId].parameterNameList.map(param => Object.assign({}, param));

      //各パラメーターごとに計算処理を実施していく
      const parameterCalcResult = this.calcTotal(characterInfo, classList, equipItemList, equipSkillList, parameterChangeHistory);

      //parameterNameListにparameterCalcResultをsetしていく
      parameterNameList.forEach((parameterName) => {
        parameterName.value = parameterCalcResult[parameterName.id - 1];
      });

      return parameterNameList;
    },
    //全体的なパラメーター計算処理
    calcTotal(characterInfo, classInfoList, itemList, skillList, parameterChangeHistory) {
      const parameterCalcInnerGroupList = this.$store.state.parameterInfo[this.$store.state.worldId].parameterCalcInnerGroupList;
      const parameterCalcBetweenGroupList = this.$store.state.parameterInfo[this.$store.state.worldId].parameterCalcBetweenGroupList;
      let result = Array(30);
      for (const outerGroup of parameterCalcBetweenGroupList) {
        let innerCalcResult = 0;

        for (const innerGroup of parameterCalcInnerGroupList) {
          //パラメーターIDが一致しない場合スキップ
          if (outerGroup.parameterId !== innerGroup.parameterId) {
            continue;
          }

          //グループIDが一致しない場合スキップする
          if (outerGroup.groupId !== innerGroup.groupId) {
            continue;
          }

          innerCalcResult = this.doCalc(innerCalcResult, characterInfo, itemList, skillList, classInfoList, parameterChangeHistory, innerGroup, result);
          if(innerGroup.parameterId===5){
          }
        }

        //between処理を行う
        result = this.reflectCalcResult(result, innerCalcResult, outerGroup);
      }

      return result;

    },

    doCalc(innerCalcResult, characterInfo, itemList, skillList, classInfoList, parameterChangeHistory, innerGroup, result) {
      const calcTarget = innerGroup.calcTarget;
      const calcMethod = innerGroup.calcMethod;
      const parameterId = innerGroup.parameterId;

      let key;
      switch (calcTarget) {
        case "classA":
          key =  `noryokuKijun${parameterId}`;
          break;
        case "changeHistory":
          key =  `parameter${parameterId}`;
          break;
        default:
          key =  `noryokuHosei${parameterId}`;
          break;
      }
      switch (calcTarget) {
        case "classA":
          //内部で使用するパラメーターごとにswitch
          innerCalcResult = this.getCalcResult(calcMethod, innerCalcResult, classInfoList[0][key]);
          break;
        case "classB":
          innerCalcResult = this.getCalcResult(calcMethod, innerCalcResult, classInfoList[1][key]);
          break;
        case "classC":
          innerCalcResult = this.getCalcResult(calcMethod, innerCalcResult, classInfoList[2][key]);
          break;
        case "items":
          if (itemList && itemList.length > 0) {
            innerCalcResult = this.innerCalcWithItems(innerCalcResult, itemList, innerGroup, parameterId, calcMethod);
          }
          break;
        case "skills":
          if (skillList && skillList.length > 0) {
            innerCalcResult = this.innerCalcWithSkills(innerCalcResult, skillList, innerGroup, parameterId, calcMethod);
          }
          break;
        //重量ボーナスのような特殊な計算はシステム対応としてお金をとって良い類のことだと思うので、極端な汎用化はしません。
        case "heavyEffectWorld0":
          innerCalcResult = this.innerCalcWithHeavyEffectWorld0(innerCalcResult, characterInfo, itemList, innerGroup, classInfoList[0], classInfoList[1], parameterId);
          break;
        case "level":
          innerCalcResult = this.getCalcResult(calcMethod, innerCalcResult, characterInfo.level);
          break;
        case "Number":
          innerCalcResult = this.getCalcResult(calcMethod, innerCalcResult, innerGroup.parameter);
          break;
        case "pastParm":
          innerCalcResult = this.getCalcResult(calcMethod, innerCalcResult, result[innerGroup.parameter - 1]);
          break;
        case "changeHistory":
          innerCalcResult = this.getCalcResult(calcMethod, innerCalcResult, parameterChangeHistory[key]);
          break;
      }
      return innerCalcResult;
    },

    innerCalcWithSkills(innerCalcResult, skillList, innerGroup, parameterId, calcMethod){

      for (const data of skillList) {
        const itemParam = this.addParamForSkill(data, parameterId);
        //null check
        if (itemParam) {
          innerCalcResult = innerCalcResult + itemParam;
        }
      }
      return innerCalcResult;
    },

    innerCalcWithItems(innerCalcResult, itemList, innerGroup, parameterId, calcMethod) {

      for (const data of itemList) {
        const itemParam = this.addParamForItem(data, parameterId);
        //null check
        if (itemParam) {
          innerCalcResult = innerCalcResult + itemParam;
        }

      }
      return innerCalcResult;
    },

    addParamForSkill(itemData, parameterId) {

      const key = `noryokuHosei${parameterId}`;

      //アイテムデータの反映フラグがtrueじゃない場合、return
      if (itemData.skillHaneiFlg !== "1") {
        return 0;
      }else if(itemData[key] === null) {
        return 0;
      }

      return itemData[key];
    },
    addParamForItem(itemData, parameterId) {
      const key = `noryokuHosei${parameterId}`;

      //itemDataのitemIdがnullの場合、return
      if(itemData.itemId==null){
        return 0;
      }
      if (itemData.statusRefFlg !== 1) {
        return 0;
      }

      //アイテムデータで武器フラグがfalseの場合、そのまま値を返す

      if (!itemData.weaponFlg) {
        return itemData[key];
      }

      //武器の場合、itemPositionIdを確認
      //ポジションが１（主武器）または副装備ステータスに値を返す
      //TODO:アイテムデータにweaponFlgと一緒にmainWeaponFlgを追加して判定する
      if (itemData.weaponFlg == 1) {
        if (itemData.itemPositionId === 1 || itemData.equipPositionId === 1 || itemData.subStatusFlg === 1) {
          return itemData[key];
        } else {
          return 0;
        }
      }
    },

    getCalcResult(calcMethod, beforeResult, value) {
      let result = 0;
      switch (calcMethod) {
        case 1:
          result = beforeResult + value;
          break;
        case 2:
          result = beforeResult - value;
          break;
        case 3:
          result = Math.round(beforeResult * value);
          break;
        case 4:
          result = Math.round(beforeResult / value);
          break;
        case 5:
          result = Math.floor(beforeResult / value);
          break;
      }
      return result;
    },

    //重量ボーナスのような特殊な計算はシステム対応としてお金をとって良い類のことだと思うので、極端な汎用化はしません。
    innerCalcWithHeavyEffectWorld0(innerCalcResult, characterInfo, itemList, innerGroup, classInfoListElement, classInfoListElement2, parameterId) {
      //コスト計算
      const costBase = classInfoListElement2.noryokuHosei16;
      const costMax = costBase + characterInfo.level;
      let costItems = 0;

      if (itemList && itemList.length > 0) {
        for (const itemData of itemList) {
          costItems = costItems + itemData.itemCost;
        }
      }

      let heavyFlg = 0;
      if (costItems !== 0) {
        heavyFlg = Math.round((100 * costItems / costMax));
      }

      //移動の場合
      if (parameterId === 8) {
        if (heavyFlg >= 85) {
          return innerCalcResult - 2;
        } else if (heavyFlg >= 70) {
          return innerCalcResult - 1;
        } else if (heavyFlg <= 15) {
          return innerCalcResult + 2;
        } else if (heavyFlg <= 30) {
          return innerCalcResult + 1;
        } else {
          return innerCalcResult;
        }
      } else if (parameterId === 4 || parameterId === 12) {
        if (heavyFlg >= 85) {
          return 80;
        } else if (heavyFlg >= 70) {
          return 90;
        } else if (heavyFlg <= 15) {
          return 120;
        } else if (heavyFlg <= 30) {
          return 110;
        } else {
          return 100;
        }
      }
    },
    reflectCalcResult(result, innerCalcResult, outerGroup) {
      const parameterId = outerGroup.parameterId;
      const calcMethod = outerGroup.calcMethod;
      //Nanの場合、0にする
      if(!result[parameterId-1]){
        result[parameterId-1] = 0;
      }
      result[parameterId - 1] = this.getCalcResult(calcMethod, result[parameterId - 1], innerCalcResult);
      return result;
    }
  },
};
