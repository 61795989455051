import moment from 'moment'
import axios from 'axios'

export default {
  data() {
    return {
      ShinseiStatus: {
        MiShinsei: '0',
        ShinseiChu: '1',
        ShoninZumi: '2',
        Kyakka: '3',
        SaiShinsei: '4',
        Cancel: '5',
        Miseiritsu: '9'
      },

      nameOrderList: [
        { kubunCode: '1', kubunName: '名・Ｍ・姓' },
        { kubunCode: '2', kubunName: '姓・Ｍ・名' },
        { kubunCode: '3', kubunName: '名' },
        { kubunCode: '4', kubunName: '名・姓' },
        { kubunCode: '5', kubunName: '姓・名' },
        { kubunCode: '6', kubunName: '名＝Ｍ＝姓' },
        { kubunCode: '7', kubunName: '姓＝Ｍ＝名' },
        { kubunCode: '8', kubunName: '名＝姓' },
        { kubunCode: '9', kubunName: '姓＝名' },
        { kubunCode: '10', kubunName: '名 姓' },
        { kubunCode: '11', kubunName: '姓 名' }
      ],

      worldTokenInfoAll: {
        world0: {
          worldTokenIcon1: 'ai-coin-G',
          worldTokenIcon2: 'ai-orb',
          worldTokenIcon3: 'ai-point-sp',
          worldTokenIcon4: null,
          worldTokenLabel1: 'ゴールド',
          worldTokenLabel2: 'オーブ',
          worldTokenLabel3: 'スキルポイント',
          worldTokenLabel4: null,
          worldToken1Unit: 'G',
          worldToken2Unit: 'Orb',
          worldToken3Unit: 'SP',
          worldToken4Unit: null,
          fpLabel: 'FatePower（抽選ボーナス）'
        },
        world1: {
          worldTokenIcon1: 'money',
          worldTokenIcon2: 'ai-carma',
          worldTokenIcon3: null,
          worldTokenIcon4: null,
          worldTokenLabel1: '円',
          worldTokenLabel2: 'カルマ',
          worldTokenLabel3: null,
          worldTokenLabel4: null,
          worldToken1Unit: '円',
          worldToken2Unit: 'カルマ',
          worldToken3Unit: null,
          worldToken4Unit: null,
          fpLabel: null
        },
        world2: {
          worldTokenIcon1: 'bookmark',
          worldTokenIcon2: null,
          worldTokenIcon3: null,
          worldTokenIcon4: null,
          worldTokenLabel1: 'EP',
          worldTokenLabel2: null,
          worldTokenLabel3: null,
          worldTokenLabel4: null,
          worldToken1Unit: 'EP',
          worldToken2Unit: null,
          worldToken3Unit: null,
          worldToken4Unit: null,
          fpLabel: null
        },
        Bullbre: {
          worldTokenIcon1: 'ai-devil',
          worldTokenIcon2: 'ai-coin-Skill',
          worldTokenIcon3: 'ai-coin-Skull',
          worldTokenIcon4: null,
          worldTokenLabel1: '魔界ドル',
          worldTokenLabel2: 'スキルポイント',
          worldTokenLabel3: '悪徳ポイント',
          worldTokenLabel4: null,
          worldToken1Unit: '＄',
          worldToken2Unit: 'SP',
          worldToken3Unit: 'WP',
          worldToken4Unit: null,
          fpLabel: null
        },
        insomnia1999: {
          worldTokenIcon1: 'money',
          worldTokenIcon2: 'ai-pot',
          worldTokenIcon3: null,
          worldTokenIcon4: null,
          worldTokenLabel1: '円',
          worldTokenLabel2: 'ピトス',
          worldTokenLabel3: null,
          worldTokenLabel4: null,
          worldToken1Unit: '円',
          worldToken2Unit: 'ピトス',
          worldToken3Unit: null,
          worldToken4Unit: null,
          fpLabel: null
        }
      }
    }
  },

  filters: {
    //カンマ区切りと追加
    addComma(val) {
      if (!val) return '0'
      return val.toLocaleString()
    }
  },

  methods: {
    //objejctLogListをかえす

    createObjectListLog(defaultObjectList, eventDetailList) {
      // オブジェクトリストのログを作成
      let objectListLog = {}

      //eventDetailListからLogNoを取得し、objectListLogに格納
      eventDetailList.forEach((eventDetail) => {
        let logNo = eventDetail.logNo
        objectListLog[logNo] = []
      })

      let beforeObjectList = defaultObjectList

      eventDetailList.forEach((eventDetail) => {
        let logNo = eventDetail.logNo
        let objectChangedHistoryList = eventDetail.objectChangedHistoryList
        objectChangedHistoryList.forEach((objectChangedHistory) => {
          let objectId = objectChangedHistory.objectId
          let objectTitle = objectChangedHistory.objectTitle
          let objectContent = objectChangedHistory.objectContent
          let beforeObject = beforeObjectList.find((object) => object.objectId === objectId)
          if (beforeObject) {
            objectListLog[logNo].push({
              objectId: objectId,
              before: {
                title: beforeObject.objectTitle,
                content: beforeObject.objectContent,
                objectType: beforeObject.objectType
              },
              after: {
                title: objectTitle,
                content: objectContent,
                objectType: objectChangedHistory.objectType
              }
            })
          }
          //beforeObjectListを更新
          beforeObjectList = beforeObjectList.map((object) => {
            if (object.objectId === objectId) {
              return {
                objectId: objectId,
                objectTitle: objectTitle,
                objectContent: objectContent,
                objectType: objectChangedHistory.objectType
              }
            } else {
              return object
            }
          })
        })
      })
      return objectListLog
    },


    //申請ステータスに応じたtextを返す
    getShinseiStatus(shinseiStatus) {
      switch (shinseiStatus) {
        case '0':
          return '未申請'
        case '1':
          return '申請中'
        case '2':
          return '承認済'
        case '3':
          return '却下'
        case '4':
          return '再申請'
        case '5':
          return 'キャンセル'
        case '9':
          return '未成立'
      }
    },


    async getWorldList() {
      try {
        const res = await axios.get(this.serverPath + '/api/WorldSelect')
        if (res.data) {
          return res.data.worldSelectList

        }
      } catch (error) {
        console.log(error)
      }
    },
    //excのステータス表示

    getExcStatus(npcStatus) {
      switch (npcStatus) {
        case '2':
          return '使用不可'
        case '3':
          return 'MS使用可／死亡不可'
        case '4':
          return 'MS使用可／死亡可'
      }
    },

    //お気に入りを取得
    getFavoriteReadyMadeSetList: async function() {
      axios
        .get(this.serverPath + '/api/ReadyMade/liked/findAll')
        .then((res) => {
          this.$store.dispatch('readyMadeLikedList', {
            readyMadeLikedList: res.data.setIdList
          })
        })
        .catch((err) => {
          //console.log(err);
        })
    },
    getReadyMadeSetMaster: async function(creatorKbn) {
      const res = await axios.get(
        this.serverPath + `/api/ReadyMade/getReadyMadeSetMaster`,
        {
          params: {
            creatorKbn: creatorKbn
          }
        }
      )
      if (res.data) {
        return res.data
      }
    },
    getWorldIcon(worldId) {
      return this.assetsPath + `/w_assets/${worldId}/banner/1.png`
    },
    truncateChar: function(str, value) {
      if (str.length > value) {
        return str.substring(0, value) + '...'
      }
      return str
    },
    parseLocalTimeStamp(val) {
      //"2023-12-30 04:43:42"形式を YYYY年MM月DD日 HH:mm形式に変換
      if (!val) return ''
      return moment(val).format('YYYY年MM月DD日 HH時mm分')
    },
    // GET METHOD axios
    // worldCounter/{worldId}/{worldCounterId}
    getWorldCounter: async function(worldId, counterId) {
      const res = await axios.get(
        this.serverPath + `/api/WorldTop/worldCounter/${worldId}/${counterId}`
      )
      return res.data
    },
    //POST
    ///worldCounter/admin/update
    // body:worldCounterId
    updateWorldCounter: async function(worldCounterId) {
      const body = {
        worldCounterId: worldCounterId
      }
      const res = await axios.post(
        this.serverPath + `/api/WorldTop/worldCounter/admin/update`,
        body
      )
      return res.data
    },

    communityImageStyle(
      communityImage,
      communityImagePositionY,
      isCharacterSheet
    ) {
      // 画像URL
      const backgroundImage = isCharacterSheet
        ? `url("${this.objectPath}/images/main/${communityImage}/communityImage/${communityImage}.jpg")`
        : `url("${this.objectPath}/images/main/${communityImage}/communityImageLarge/${communityImage}.webP")`

      // 画像サイズ
      const backgroundSize = 'cover'

      // Y軸
      const backgroundPositionY = 100 - Number(communityImagePositionY) + '%'

      // X軸（画像の左右位置は 'center' に固定しています）
      const backgroundPositionX = 'center'

      return `
      position: relative;
      width:100%;
      padding-bottom: 33.33%;
      background-image: ${backgroundImage};
      background-size: ${backgroundSize};
      background-position-y: ${backgroundPositionY};
      background-position-x: ${backgroundPositionX};
    `
    },
    refreshFrontEnd: async function() {
      await this.$store.dispatch('fetchLastUpdated', new Date().toISOString())
      location.reload()
    },
    checkFrontEndVersion: async function() {
      const response = await fetch('/version.json')
      return await response.json()
    },
    getCommunityThreadNotification: async function() {
      const res = await axios.get(
        this.serverPath + `/api/communityNotification/thread/worldNotice`
      )
      if (res.data) {
        await this.$store.dispatch(
          'communityThreadListForNotification',
          res.data.notificationList
        )
      }
    },
    addTailorGalleryLimitationForCharacter: async function(characterId) {
      return await axios
        .post(
          this.serverPath +
          `/api/TailorGalleryLimitation/createLimitationForCharacter/${characterId}`
        )
        .then(() => {
          this.getTailorGalleryLimitationInfo()
        })
    },
    deleteTailorGalleryLimitationForCharacter: async function(characterId) {
      await axios
        .post(
          this.serverPath +
          `/api/TailorGalleryLimitation/deleteLimitationForCharacter/${characterId}`
        )
        .then(() => {
          this.getTailorGalleryLimitationInfo()
        })
    },
    addTailorGalleryLimitationForOrder: async function(shouhinId) {
      await axios
        .post(
          this.serverPath +
          `/api/TailorGalleryLimitation/createLimitationForOrder/${shouhinId}`
        )
        .then(() => {
          this.getTailorGalleryLimitationInfo()
        })
    },

    deleteTailorGalleryLimitationForOrder: async function(shouhinId) {
      await axios
        .post(
          this.serverPath +
          `/api/TailorGalleryLimitation/deleteLimitationForOrder/${shouhinId}`
        )
        .then(() => {
          this.getTailorGalleryLimitationInfo()
        })
    },

    getTailorGalleryLimitationInfo: async function() {
      const res = await axios.get(
        this.serverPath + `/api/TailorGalleryLimitation/getLimitationList`
      )
      if (res.data) {
        await this.$store.dispatch('tailorGalleryLimitationInfo', res.data)
      }
    },
    //所持コインの確認
    getCoinzandaka: async function() {
      const res = await axios({
        method: 'get',
        url: this.serverPath + '/api/coin/getCoins'
      })
      if (res.data) {
        const alpacaCoin = res.data.alpacaCoin
        const llamaPoint = res.data.llamaPoint
        return {
          alpacaCoin: alpacaCoin,
          llamaPoint: llamaPoint
        }
      }
    },

    getWorldTokenInfo(worldId) {
      return this.worldTokenInfoAll[worldId]
    },
    assignDefined(target, ...sources) {
      for (const source of sources) {
        for (const key of Object.keys(source)) {
          const value = source[key]
          if (value !== undefined) {
            target[key] = value
          }
        }
      }
      return target
    },

    makeStatusList(
      characterInfo,
      classList,
      equipItemList,
      equipSkillList,
      parameterChangeHistory
    ) {
      //store内のparameterNameListを取得し、パラメーター計算用のリストを作成
      let parameterNameList = this.$store.state.parameterInfo[
        this.$store.state.worldId
        ].parameterNameList.map((param) => Object.assign({}, param))

      //各パラメーターごとに計算処理を実施していく
      const parameterCalcResult = this.calcTotal(
        characterInfo,
        classList,
        equipItemList,
        equipSkillList,
        parameterChangeHistory
      )

      //parameterNameListにparameterCalcResultをsetしていく
      parameterNameList.forEach((parameterName) => {
        parameterName.value = parameterCalcResult[parameterName.id - 1]
        parameterName.zogen = 0
      })

      return parameterNameList
    },
    makeSelectedClassList: (classA, classB, classC) => {
      let selectedClassList = []
      selectedClassList.push(classA)
      selectedClassList.push(classB)
      selectedClassList.push(classC)
      return selectedClassList
    },
    makeParameterNameList() {
      let parameterNameList = []

      //storeのparameterInfoにあるworldItemNameMasterを参照していき、paramIdをキーにして、paramNameを設定していく
      //まず、worldItemNameMasterの各キーを取得してforで回していく
      const keyAry = Object.keys(
        this.$store.state.parameterInfo[this.$store.state.worldId][
          'worldItemNameMaster'
          ]
      )

      //keyAryをforで回す
      for (const key of keyAry) {
        //KeyAryの中でnoryokuNameを持っているものを取得し、その後ろのNumをidとして取得する
        if (key.indexOf('noryokuName') !== -1) {
          const id = key.replace('noryokuName', '')
          const name = this.$store.state.parameterInfo[
            this.$store.state.worldId
            ].worldItemNameMaster[key]
          const parameterName = {
            id: id,
            name: name
          }
          //nameが空の場合はpushしない
          if (parameterName.name !== '') {
            parameterNameList.push(parameterName)
          }
        }
      }
      //できあがったparameterNameListをstoreにdispatchする
      //parameterInfo>worldId>parameterNameListにparameterNameListを入れる
      let data = this.$store.state.parameterInfo
      data[this.$store.state.worldId]['parameterNameList'] = parameterNameList

      //storeにdispatchする
      this.$store.dispatch('parameterInfo', data)
    },
    makeEquipItemListIndex(item) {
      if (item.quantity === 1) {
        return ''
      } else {
        return item.equipNo
      }
    },
    makeEquipSkillListView: function(equipSkillList, equipSkillPositionList) {
      //枠を作成
      let equipSkillListForView = []
      for (const data of equipSkillPositionList) {
        for (let i = 1; i <= data.quantity; i++) {
          const newData = { ...data } // equipSkillPositionListを変更しないように複製する
          newData.equipNo = i
          equipSkillListForView.push(newData)
        }
      }
      //equipSkillListから、equipPositionIdとequipNoが一致するものをequipSkillListForViewに入れる
      for (const data of equipSkillListForView) {
        data.equipSkill = {
          equipPositionId: data.equipPositionId,
          equipNo: data.equipNo,
          skillId: null
        } // 初期値を設定する
        for (const equipSkill of equipSkillList) {
          if (
            data.equipPositionId == equipSkill.equipPositionId &&
            data.equipNo == equipSkill.equipNo
          ) {
            data.equipSkill = equipSkill
            break // 一致したらループを抜ける
          }
        }
      }
      return equipSkillListForView
    },
    makeEquipItemListView: function(equipItemList, equipItemPositionList) {
      let equipItemListForView = []

      //リストの枠を作成
      for (const data of equipItemPositionList) {
        for (let i = 1; i <= data.quantity; i++) {
          const newData = { ...data } // equipItemPositionListを変更しないように複製する
          newData.equipNo = i
          equipItemListForView.push(newData)
        }
      }

      //equipItemListから、positionIdとequipNoが一致するものをequipItemListForViewに入れる
      for (const data of equipItemListForView) {
        data.equipItem = undefined // 初期値を設定する
        for (const equipItem of equipItemList) {
          if (
            data.equipPositionId == equipItem.equipPositionId &&
            data.equipNo == equipItem.equipNo
          ) {
            data.equipItem = equipItem
            break // 一致したらループを抜ける
          }
        }
      }
      return equipItemListForView
    },
    getParameterCalcInfo: async function(worldId) {
      try {
        const res = await axios.get(
          this.serverPath + `/api/WorldTop/getParameterCalcInfo/${worldId}`
        )
        if (res.data) {
          //parameterInfoの下位にworldIdをkeyとして、res.dataを入れてstoreにdispatchする
          let parameterInfo

          if (this.$store.state.parameterInfo) {
            parameterInfo = this.$store.state.parameterInfo
          } else {
            parameterInfo = {}
          }

          //ワールドIdをキーにして、parameterInfoを保存
          parameterInfo[worldId] = res.data

          //storeにdispatchして保存
          await this.$store
            .dispatch('parameterInfo', parameterInfo)
            .then(() => {
              this.makeParameterNameList(
                this.$store.state.parameterInfo[worldId]
              )
            })
        }
      } catch (error) {
        console.log(error)
      }
    },
    getParameterNameMasterList: async function(worldId) {
      try {
        const res = await axios.get(
          this.serverPath +
          `/api/CharacterMypage/getParameterNameList/${worldId}`
        )
        if (res.data) {
          return res.data
        }
      } catch (error) {
        console.log(error)
      }
    },
    makeEquipItemList(equipPositionList, sobiItemList) {
      const dataList = []

      //装備枠を作成
      for (const positionData of equipPositionList) {
        for (let i = 0; i < positionData.quantity; i++) {
          let positionNo = ''
          if (positionData.quantity > 1) {
            positionNo = i + 1
          }
          const data = {
            itemPositionId: positionData.itemPositionId,
            icon: '',
            iconName: '',
            itemBunruiName: positionData.positionName + positionNo,
            equipNo: i + 1,
            itemId: null,
            originalize: 0,
            itemName: null,
            levelupCostPatternName: null,
            level: 0,
            maxLevel: 0,
            itemCost: null
          }
          dataList.push(data)
        }
      }
      for (const data of dataList) {
        for (const item of sobiItemList) {
          if (
            item.equipPositionId === data.itemPositionId &&
            item.equipNo === data.equipNo
          ) {
            const itemBunruiName = data.itemBunruiName
            Object.assign(data, item)
            data.itemBunruiName = itemBunruiName
            break
          }
        }
      }
      return dataList
    },
    // コインタイプ名称を返す
    coinTypeName(coinType) {
      switch (coinType) {
        case 0:
          return 'AC'
        case 1:
          return 'LP'
        case 2:
          return 'CP'
        default:
          break
      }
    },

    // キャラクターシートDialogを開く
    pushCharacterSheet(
      characterId = this.$store.state.characterId,
      mode = 'sheet'
    ) {
      // 先頭と同値の場合はpushしない
      if (
        this.$root.CharacterSheetId.length == 0 ||
        characterId != this.$root.CharacterSheetId[0][0] ||
        mode != this.$root.CharacterSheetId[0][1]
      ) {
        if (characterId || mode == 'scenarioSankaList') {
          this.$root.CharacterSheetId.unshift([characterId, mode])
        }
      }
      this.$root.DialogCharacterSheet = true
      this.changeCharacterSheetMini(false)
    },
    sleep(msec) {
      return new Promise(function(resolve) {
        setTimeout(function() {
          resolve()
        }, msec)
      })
    },
    changeCharacterSheetMini(val) {
      // console.log("mode");
      // console.log(val);
      this.$root.CharacterSheetMini = val
      if (val) {
        this.$root.DialogCharacterSheet = false
      } else {
        this.$root.DialogCharacterSheet = true
      }
    },

    addIconForCharacterSheet(icon) {
      // console.log("addIcon");
      this.$root.CharacterSheetId[0].splice(2, 1, icon)
    },

    // キャラクターシートDialog　戻る
    popCharacterSheet() {
      // console.log("pop");
      if (!this.CharacterSheetId) {
        this.$root.DialogCharacterSheet = false
        this.$root.CharacterSheetMini = false
      }
      this.$nextTick(() => {
        this.$root.CharacterSheetId.splice(0, 1)
      })
    },

    // キャラクターシートDialogを閉じる
    closeCharacterSheet() {
      // console.log("close");
      this.$root.DialogCharacterSheet = false
      this.$nextTick(() => {
        this.$root.CharacterSheetId.splice(0, this.CharacterSheetId.length)
        this.$root.CharacterSheetMini = false
      })
    },

    //characterGalleryIconを返すための処理
    galleryIconSelect(item) {
      //console.log(item);
      if (item.galleryIcon) {
        return item.galleryIcon
      } else {
        return item.characterIcon
      }
    },

    // TODO:BE処理にする
    extractShouhinId(url) {
      if (String(url).indexOf('/api/') == 0) {
        let ret = url.replace('/api/CreatorImage/', '')
        let ret2 = ret.replace('/api/Image/', '')
        let index = ret2.indexOf('/')
        return index == -1 ? ret2 : ret2.substring(0, index)
      }
      return url
    },

    splitSlash(str, i) {
      return str.split('/')[i]
    },

    //改行をbrに
    replaceBr(text) {
      if (!text) return text
      return text.replace(/\n/g, '<br/>')
    },

    //アルパカ記法の適用
    replaceNotation(text) {
      if (text) {
        return text
          .replace(
            /《《(.+?)》》/g,
            '<span class=\'td-emphasis\'>《emphasis《$1》emphasis》</span>'
          )
          .replace(/《emphasis《(.+?)》emphasis》/g, (_, key) =>
            key.split('').reduce((acc, v) => {
              return acc + `<span>${v}</span>`
            }, '')
          )
          .replace(
            /[\|｜](.+?)《(.+?)》/g,
            '<ruby>$1<rp>(</rp><rt>$2</rt><rp>)</rp></ruby>'
          )
          .replace(
            /[\|｜](.+?)（(.+?)）/g,
            '<ruby>$1<rp>(</rp><rt>$2</rt><rp>)</rp></ruby>'
          )
          .replace(
            /[\|｜](.+?)\((.+?)\)/g,
            '<ruby>$1<rp>(</rp><rt>$2</rt><rp>)</rp></ruby>'
          )
          .replace(
            /([一-龠]+)《(.+?)》/g,
            '<ruby>$1<rp>(</rp><rt>$2</rt><rp>)</rp></ruby>'
          )
          .replace(/[\|｜]《(.+?)》/g, '《$1》')
          .replace(/[\|｜]（(.+?)）/g, '（$1）')
          .replace(/[\|｜]\((.+?)\)/g, '($1)')
          .replace(/\n/g, '<br/>')
      } else {
        return text
      }
    },

    //傍点用の変換
    replaceEmphasis(text) {
      return text.split('').reduce((acc, v) => {
        return acc + `<span>${v}</span>`
      }, '')
    },

    getPhotoshopRoomType(roomType) {
      if (roomType === '0') {
        return 'パカ☆ショットβ'
      }
    },

    //キャラクター名表示
    fullName: function(name, middleName, surname, nameOrder) {
      var ary = []
      var characterName = ''
      switch (nameOrder) {
        case '1':
        case '6':
          ary = [name, middleName, surname]
          break
        case '2':
        case '7':
          ary = [surname, middleName, name]
          break
        case '3':
          ary = [name]
          break
        case '4':
        case '8':
        case '10':
          ary = [name, surname]
          break
        case '5':
        case '9':
        case '11':
          ary = [surname, name]
          break
      }
      switch (nameOrder) {
        case '6':
        case '7':
        case '8':
        case '9':
          characterName = ary.filter(Boolean).join('＝')
          break
        case '10':
        case '11':
          characterName = ary.filter(Boolean).join(' ')
          break
        default:
          characterName = ary.filter(Boolean).join('・')
      }
      return characterName
    },

    crossCount(v) {
      const ary = v.split('\n') //改行で切り分け
      let ary2 = []
      ary.forEach((text) => ary2.push(text.replace(/^.+\：/, ''))) //行頭のキャラクター名を除外
      return ary2.join('\n')
    },
    wordCount: function(str) {
      var result = 0
      if (str) {
        for (var i = 0; i < str.length; i++) {
          var chr = str.charCodeAt(i)
          if (chr === 0x000a || chr === 0x000d000a) {
            //改行は0
            continue
          } else if (
            !(
              (
                chr === 91 || // '['
                chr === 93 || // ']'
                chr === 123 || // '{'
                chr === 125 || // '}'
                chr === 40 || // '('
                chr === 41 || // ')'
                chr === 65378 || // '｢'
                chr === 65379
              ) // '｣'
            ) &&
            ((chr >= 0x00 && chr < 0x81) || //英数字、句読点、制御文字
              chr === 0xf8f0 ||
              (chr >= 0xf8f1 && chr < 0xf8f4))
          ) {
            //半角0.5
            result += 0.5
          } else {
            //それ以外1
            result += 1
          }
        }
      }
      return result
    },

    wordCountNotation(src) {
      if (src) {
        const src2 = src
          .replace(/《《(.+?)》》/g, '$1')
          .replace(/[\|｜](.+?)《(.+?)》/g, '$1')
          .replace(/[\|｜](.+?)（(.+?)）/g, '$1')
          .replace(/[\|｜](.+?)\((.+?)\)/g, '$1')
          .replace(/([一-龠]+)《(.+?)》/g, '$1')
          .replace(/[\|｜]《(.+?)》/g, '《$1》')
          .replace(/[\|｜]（(.+?)）/g, '（$1）')
          .replace(/[\|｜]\((.+?)\)/g, '($1)')
          .replace(/\n/g, '')
        return this.wordCount(src2)
      } else {
        return 0
      }
    },

    toMillisecs(date) {
      if (date instanceof Object && 'toDate' in date) {
        return date.toDate().getTime()
      }
      return date
    },

    //日付表示
    moment: function(date) {
      return moment(this.toMillisecs(date)).format('YYYY/MM/DD HH:mm')
    },
    momentS: function(date) {
      return moment(this.toMillisecs(date)).format('YYYY/MM/DD HH:mm:ss')
    },
    momentD: function(date) {
      return moment(this.toMillisecs(date)).format('YYYY/MM/DD')
    },
    momentFromNow: function(date) {
      moment.locale('ja')
      return moment(this.toMillisecs(date)).fromNow()
    },
    momentJa: function(date) {
      return moment(this.toMillisecs(date)).format('YYYY年MM月DD日 HH:mm')
    },
    momentDate: function(date) {
      return moment(this.toMillisecs(date)).format('YYYY年MM月DD日')
    },
    momentAdd: function(date, y, m, d, h) {
      return moment(this.toMillisecs(date))
        .add(y, 'years')
        .add(m, 'months')
        .add(d, 'days')
        .add(h, 'hours')
    },

    weightRule: function(list, sobiCost, maxCost) {
      // 重量ルールチェック
      const rate = sobiCost / maxCost
      if (rate >= 0.85) {
        // 装備コストが最大コストの85％以上　移動　2低下 　回避20％低下　INI値20％ダウン
        list[7].weightZogen = -2
        list[3].weightZogen = Math.floor(list[3].charaNoryoku * 0.2) * -1
        list[11].weightZogen = Math.floor(list[11].charaNoryoku * 0.2) * -1
      } else if (rate >= 0.7) {
        // 装備コストが最大コストの70％以上　移動　1低下 　回避10％低下　INI値10％ダウン
        list[7].weightZogen = -1
        list[3].weightZogen = Math.floor(list[3].charaNoryoku * 0.1) * -1
        list[11].weightZogen = Math.floor(list[11].charaNoryoku * 0.1) * -1
      } else if (rate <= 0.3 && rate > 0.15) {
        // 装備コストが最大コストの30％以下　移動　1上昇 　回避10％上昇　INI値10％アップ
        list[7].weightZogen = 1
        list[3].weightZogen = Math.floor(list[3].charaNoryoku * 0.1)
        list[11].weightZogen = Math.floor(list[11].charaNoryoku * 0.1)
      } else if (rate <= 0.15) {
        // 装備コストが最大コストの15％以下　移動　2上昇 　回避20％上昇　INI値20％アップ
        list[7].weightZogen = 2
        list[3].weightZogen = Math.floor(list[3].charaNoryoku * 0.2)
        list[11].weightZogen = Math.floor(list[11].charaNoryoku * 0.2)
      } else {
        // 重量ルールによる増減なし
        list[7].weightZogen = 0
        list[3].weightZogen = 0
        list[11].weightZogen = 0
      }
      return list
    },
    weightRuleClass: function(sobiCost, maxCost) {
      // 装備重量のルールにより適用するクラス
      var num = 0
      var rate = sobiCost / maxCost

      if (rate >= 0.85) {
        // 装備コストが最大コストの85％以上
        num = 1
      } else if (rate >= 0.7) {
        // 装備コストが最大コストの70％以上
        num = 2
      } else if (rate <= 0.3 && rate > 0.15) {
        // 装備コストが最大コストの30％以下 かつ 最大コストの15％より大きい
        num = 3
      } else if (rate <= 0.15) {
        // 装備コストが最大コストの15％以下
        num = 4
      }

      return num
    },
    downloadImg: function(url, fileName) {
      return new Promise((resolve, reject) => {
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.style.display = 'none';
        document.body.appendChild(link);

        link.onclick = () => {
          setTimeout(() => {
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
            resolve();
          }, 150);
        };

        link.click();
      });
    },
    downloadMp3: function(url, fileName) {
      return fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = fileName;
          link.style.display = 'none';
          document.body.appendChild(link);

          link.click();

          setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(link);
          }, 150);
        });
    },

    filterScenarioId(scenarioId) {
      /* シナリオIDを前ゼロ付きに変換 */
      if (scenarioId) {
        return ('0000000000' + Number(scenarioId)).slice(-10)
      }
      return undefined
    },

    //リストから値を返す
    showValue(items, value, itemValue, itemText) {
      if (items.length == 0) {
        return value
      }
      const obj = items.find((u) => u[itemValue] === value)
      return obj[itemText]
    }
  },
  computed: {

    isV2Scenario() {
      //"world0","Bullbre","world2"以外はV2シナリオ
      if (this.$store.state.worldId === 'world0' || this.$store.state.worldId === 'Bullbre' || this.$store.state.worldId === 'world2') {
        return false
      } else {
        return true
      }
    },
    worldTokenInfo() {
      return this.getWorldTokenInfo(this.$store.state.worldId)
    },
    isSelectedCharacter: function() {
      if (this.$store.state.characterId) {
        return true
      }
      return false
    },
    isLogin: function() {
      if (this.$store.state.userId) {
        return true
      }
      return false
    }
  }
}
